<template>
   <div class="obi-dropdown dropdown" :attrs="attrs" v-click-outside="closeDropdown">
      <ObiDropdownToggle :as="as" :isLoading="isLoading" ref="elmDropdown" :title="title" :outline="outline">
         <slot name="toggle"></slot>
         <slot name="title"></slot>
      </ObiDropdownToggle>

      <ul :class="`dropdown-menu ${as}`" style="max-height: 500px; overflow: scroll;">
         <slot />
      </ul>
   </div>
</template>

<script>
import { Dropdown } from 'bootstrap';
import { omit, keys } from 'lodash';

export default {
   name: 'ObiDropdown',
   props: {
      title: {
         type: String,
      },
      isLoading: {
         type: Boolean,
         default: false,
      },
      outline: {
         type: Boolean,
         default: false,
      },
      as: {
         type: String,
      },
   },
   computed: {
      attrs() {
         return omit(this.$attrs, keys(this.$props));
      },
   },
   data() {
      return {
         dropDown: null,
      };
   },
   mounted() {
      const intrvl = setInterval(() => {
         if (!this.$refs.elmDropdown) {
            return;
         }

         this.dropDown = new Dropdown(this.$refs.elmDropdown.$el);

         clearInterval(intrvl);
      }, 10);
   },
   methods: {
      closeDropdown() {
         this.dropDown.hide();
      },
   },
};
</script>
<style lang="scss" scoped>
.obi-dropdown {
   .dropdown-menu {
      max-width: 300px !important;
      &.notification {
         min-width: 400px !important;
         border: none !important;
      }
      &.team-list {
         max-height: 400px;
         overflow: auto;
      }
   }
}
</style>
